@import '/src/assets/fonts/font-face.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Gilroy',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.swal2-container {
  z-index: 1460;
  background: rgba(0,0,0,.6) !important;
}
.swal2-backdrop-show {
}
.swal2-popup {
  font-family: Gilroy, sans-serif;
  color: #000;
  border-radius: 24px;
  padding: 0;
  width: 100%;
  max-width: 456px;
  .swal2-close {
    font-size: 2.7em;
    border-radius: 50%;
    color: #000;
    &:hover {
      color: #ff6565;
    }
  }
  .swal2-title {
    padding: 32px 32px 0 32px;
  }
  .swal2-html-container {
    padding: 10px 10px 0 10px;
    margin: 0 32px;
  }
  .swal2-actions {
    margin: 15px 32px 32px 32px;
    .swal2-confirm {
      width: 100%
    }
  }
  .swal2-confirm {
    border-radius: 12px;
  }
}
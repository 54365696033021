@font-face {
  font-family: 'Gilroy';
  src: url('./Gilroy-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./Gilroy-Regular.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}


@font-face {
  font-family: 'Gilroy';
  src: url('./Gilroy-SemiBold.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./Gilroy-Bold.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./Gilroy-ExtraBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./Gilroy-Black.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./Gilroy-Heavy.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./Gilroy-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./Gilroy-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}